import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

import Slider from "../src/components/Slider";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import { TelemedicineSection } from "./components/TelemedicineSection";
import SuccessPage from "./components/SuccessPage"; // Importa SuccessPage

function App() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>VetZoo - Dra. Déborah Bunster</title>
          <meta
            name="Clínica Veterinaria Animales Exóticos - Santiago, Chile"
            content="Veterinaria de hurones, cuyis, conejos, chinchillas, cobayas, erizos, peces, reptiles, aves, etc."
          />
          <meta name="keywords" content="huron, hurones, hurones chile, veterinario hurones, cuy, cuyi, erizo, loro" />
        </Helmet>

        <Container fluid className="header-container">
          <Header />
        </Container>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Container fluid className="hero-container">
                  <Hero openContactModal={openContactModal} />
                </Container>

                <Container fluid className="hero-container">
                  <TelemedicineSection openContactModal={openContactModal} />
                </Container>

                <Container fluid className="slider-container">
                  <Slider />
                </Container>

                <Container fluid className="footer-container">
                  <Footer />
                </Container>
              </>
            }
          />
          <Route path="/success" element={<SuccessPage />} /> {/* Ruta para la página de éxito */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
