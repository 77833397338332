import React from 'react';
import huron_telemed from '../img/huron_telemed.jpeg';

const TelemedicineSection = () => {
  const openCalendarInNewTab = () => {
    window.open(
      'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1B58BNZyzibhUiinYl6C4dDByZogvvJTM2Li9LudbR7Q1MxCkYrid8h7ybIffZVGPlJ4tLXgnJ?gv=true',
      '_blank'
    );
  };

  const openWhatsApp = () => {
    const phoneNumber = '56986457824';
    const message = encodeURIComponent('Hola, me gustaría agendar una consulta de telemedicina.');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="bg-teal-600 text-white py-16">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-8 text-center">Telemedicina Veterinaria</h1>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <img
              src={huron_telemed}
              alt="Veterinario en videollamada"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="w-full md:w-1/2 px-4">
            <h3 className="text-2xl font-semibold mb-4">Atención veterinaria desde la comodidad de tu hogar</h3>
            <p className="mb-6">
              En VetZoo, entendemos que el cuidado de tus mascotas exóticas es una prioridad. Por eso, ofrecemos consultas
              veterinarias en línea para brindarte asesoramiento experto sin necesidad de salir de casa.
            </p>
            <ul className="mb-8">
              <li className="flex items-center mb-2">
                <i className="fas fa-check-circle mr-2"></i>
                Consultas con especialistas en animales exóticos
              </li>
              <li className="flex items-center mb-2">
                <i className="fas fa-check-circle mr-2"></i>
                Disponible para hurones, erizos, chinchillas y más
              </li>
              <li className="flex items-center mb-2">
                <i className="fas fa-check-circle mr-2"></i>
                Atención rápida y personalizada
              </li>
            </ul>
            <div className="text-center">
              {/* Botón original comentado para uso futuro */}
              {/*
              <button
                className="bg-white text-teal-600 font-bold py-2 px-4 rounded hover:bg-teal-100 transition duration-300"
                onClick={openCalendarInNewTab}
              >
                Agendar Consulta Online
              </button>
              */}
              
              {/* Nuevo botón de WhatsApp */}
              <button
                className="bg-white text-teal-600 font-bold py-2 px-4 rounded hover:bg-teal-100 transition duration-300 flex items-center justify-center mx-auto"
                onClick={openWhatsApp}
              >
                <i className="fab fa-whatsapp mr-2 text-green-500"></i> Agendar por WhatsApp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TelemedicineSection };