import React from "react";

const SuccessPage = ({ appointmentDetails }) => {
  const goToHomePage = () => {
    window.location.href = "/";
  };

  return (
    <div className="container mx-auto text-center py-16">
      <h2 className="text-4xl font-bold text-teal-600 mb-8">
        ¡Pago recibido con éxito!
      </h2>
      <p className="text-lg text-gray-800 mb-6">
        Te contactaremos para enviarte las opciones de pago y así confirmar tu cita.
      </p>
      <p className="text-lg text-gray-800 mb-6">
        Cualquier cosa, igualmente nos puedes escribir a vetzooconsulta@gmail.com
      </p>
      <button
        onClick={goToHomePage}
        className="mt-8 px-6 py-3 bg-teal-600 text-white rounded-lg font-bold hover:bg-teal-700 transition duration-300"
      >
        Volver al Inicio
      </button>
    </div>
  );
};

export default SuccessPage;
